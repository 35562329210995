const index = {
  free: {
    codefragment: "free.md",
  },

  "common-testing_utils": {
    codefragment: "common-testing_utils.md",
  },
  "common-utils": {
    codefragment: "common-utils.md",
  },
  "common-wandb": {
    codefragment: "common-wandb.md",
  },
  "common-batch": {
    codefragment: "common-batch.md",
  },
  "common-checkpointing-snapshot": {
    codefragment: "common-checkpointing-snapshot.md",
  },
  "common-device": {
    codefragment: "common-device.md",
  },
  "common-filesystem-test_infer_fs": {
    codefragment: "common-filesystem-test_infer_fs.md",
  },
  "common-filesystem-util": {
    codefragment: "common-filesystem-util.md",
  },
  "common-log_weights": {
    codefragment: "common-log_weights.md",
  },

  "common-modules-embedding": {
    codefragment: "common-modules-embedding.md",
  },

  "common-run_training": {
    codefragment: "common-run_training.md",
  },

  "common-test_device": {
    codefragment: "common-test_device.md",
  },
  "core-test_metrics": {
    codefragment: "core-test_metrics.md",
  },

  "core-test_train_pipeline": {
    codefragment: "core-test_train_pipeline.md",
  },

  "core-train_pipeline": {
    codefragment: "core-train_pipeline.md",
  },

  "core-config-base_config": {
    codefragment: "core-config-base_config.md",
  },

  "core-config-base_config_test": {
    codefragment: "core-config-base_config_test.md",
  },

  "core-config-config_load": {
    codefragment: "core-config-config_load.md",
  },
  "core-config-test_config_load": {
    codefragment: "core-config-test_config_load.md",
  },

  "core-config-training": {
    codefragment: "core-config-training.md",
  },

  "core-custom_training_loop": {
    codefragment: "core-custom_training_loop.md",
  },

  "core-debug_training_loop": {
    codefragment: "core-debug_training_loop.md",
  },
  "core-loss_type": {
    codefragment: "core-loss_type.md",
  },
  "core-losses": {
    codefragment: "core-losses.md",
  },
  "core-metric_mixin": {
    codefragment: "core-metric_mixin.md",
  },
  "core-metrics": {
    codefragment: "core-metrics.md",
  },
  "machines-environment": {
    codefragment: "machines-environment.md",
  },
  "machines-get_env": {
    codefragment: "machines-get_env.md",
  },
  "machines-is_venv": {
    codefragment: "machines-is_venv.md",
  },
  "machines-list_ops": {
    codefragment: "machines-list_ops.md",
  },
  "mertics-aggregation": {
    codefragment: "metrics-aggregation.md",
  },

  "mertics-auroc": {
    codefragment: "mertics-auroc.md",
  },

  "reader-dataset": {
    codefragment: "reader-dataset.md",
  },

  "reader-dds": {
    codefragment: "reader-dds.md",
  },
  "reader-test_dataset": {
    codefragment: "reader-test_dataset.md",
  },
  "reader-test_utils": {
    codefragment: "reader-test_utils.md",
  },
  "reader-utils": {
    codefragment: "reader-utils.md",
  },
  "tools-pq": {
    codefragment: "tools-pq.md",
  },

  "ml_logging-absl_logging": {
    codefragment: "ml_logging-absl_logging.md",
  },
  "ml_logging-test_torch_logging": {
    codefragment: "ml_logging-test_torch_logging.md",
  },
  "ml_logging-torch_logging": {
    codefragment: "ml_logging-torch_logging.md",
  },
  model: {
    codefragment: "model.md",
  },
  "optimizers-config": {
    codefragment: "optimizers-config.md",
  },
  "optimizers-optimizer": {
    codefragment: "optimizers-optimizer.md",
  },
};

export default index;
