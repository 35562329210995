import React from "react";
// import "./home.css";

const Tree = ({ something, setSomething }) => {
  return (
    <div className="navtree">
      <div>
        <ul className="tree">
          <li>
            <details open>
              <summary className="codetitle">the-algorithm-ml</summary>

              <ul style={{ color: "white" }}>
                <li>
                  <details>
                    <summary>common</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-batch")}
                      >
                        batch.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-device")}
                      >
                        device.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-log_weights")}
                      >
                        log_weights.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-run_training")}
                      >
                        run_training.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-test_device")}
                      >
                        test_device.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-testing_utils")}
                      >
                        testing_utils.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-utils")}
                      >
                        utils.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("common-wandb")}
                      >
                        wandb.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>core</summary>
                    <li>
                  <details>
                    <summary>config</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-config-base_config")}
                      >
                        base_config.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-config-base_config_test")}
                      >
                        base_config_test.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-config-config_load")}
                      >
                        config_load.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-config-test_config_load")}
                      >
                        test_config_load.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-config-training")}
                      >
                        training.py
                      </li>
                    </ul>
                  </details>
                </li>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-custom_training_loop")}
                      >
                        custom_training_loop.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-debug_training_loop")}
                      >
                        debug_training_loop.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-loss_type")}
                      >
                        loss_type.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-losses")}
                      >
                        losses.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-metric_mixin")}
                      >
                        metric_mixin.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-metrics")}
                      >
                        metrics.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-test_metrics")}
                      >
                        test_metrics.py
                      </li>

                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-test_train_pipeline")}
                      >
                        test_train_pipeline.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("core-train_pipeline")}
                      >
                        train_pipeline.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>machines</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("machines-environment")}
                      >
                        environment.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("machines-get_env")}
                      >
                        get_env.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("machines-list_ops")}
                      >
                        list_ops.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>mertics</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("mertics-aggregation")}
                      >
                        aggregation.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("free")}
                      >
                        auroc.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("free")}
                      >
                        rce.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>ml_logging</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("ml_logging-absl_logging")}
                      >
                        absl_logging.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("ml_logging-test_torch_logging")}
                      >
                        test_torch_logging.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("ml_logging-torch_logging")}
                      >
                        torch_logging.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>optimizers</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("optimizers-config")}
                      >
                        config.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("optimizers-optimizer")}
                      >
                        optimizer.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>reader</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("reader-dataset")}
                      >
                        dataset.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("reader-test_dataset")}
                      >
                        test_dataset.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("reader-test_utils")}
                      >
                        test_utils.py
                      </li>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("reader-utils")}
                      >
                        utils.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>tools</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("tools-pq")}
                      >
                        pq.py
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>root</summary>
                    <ul>
                      <li
                        style={{ cursor: "pointer" }}
                        onClick={() => setSomething("model")}
                      >
                        {" "}
                        model.py
                      </li>
                      <li class="levelzero">.gitignore</li>
                      <li class="levelzero">.pre-commit-config.yaml</li>
                      <li class="levelzero">COPYING</li>
                      <li class="levelzero">LICENSE.torchrec</li>
                      <li class="levelzero">README.md</li>
                      <li class="levelzero">pyproject.toml</li>

                    </ul>
                  </details>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Tree;
