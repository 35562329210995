import React, { useState } from "react";
import Tree from "../components/tree";
import Mark from "../components/mark";
import Navbar from "../components/Navbar";
import Markdown from "markdown-to-jsx";


function Documentation() {
  const [something, setSomething] = useState(null);
  const [checked, setChecked] = useState(false);

  const onToggle = () => {
    setChecked(!checked)
  }

  const str = '# Welcome to codeSplain\n\nThis is great!'

  const handleReload = () => {
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Navbar onToggle={onToggle} checked={checked} handleReload={handleReload} />
      <div class="row">
        <div class="col-md-3 col-lg-2 fixed-top" style={{ backgroundColor: '#1c223a', height: '100%', zIndex: 1 }}>
          <nav class="sidebar" style={{ marginTop: '70px', marginLeft: '15px' }}>
            {/* <!-- Sidebar content here --> */}
            <Tree something={something} setSomething={setSomething} />
          </nav>
        </div>
        <div class="col-md-9 col-lg-8">
          <main className="ml" style={{marginTop: '90px' }}>
            {/* <!-- Main content here --> */}

            {!something ? (
              <Markdown options={{ wrapper: 'article' }}>
                {str}
              </Markdown>
            ) : null}

            <Mark something={something} checked={checked} />

          </main>
        </div>
      </div>
    </React.Fragment>


    // <div>
    //   <Navbar/>
    //   <div className="row">
    //     <div className="col-12 col-sm-4 col-md-2 fixed-top height-100" style={{backgroundColor: '#1c223a', height: '100%', zIndex:1}}>
    //       <nav className="sidebar" style={{marginTop: '70px'}}>
    //       <Tree something={something} setSomething={setSomething} />
    //       </nav>
    //     </div>
    //     <div className="col-12 col-sm-8 col-md-8" style={{marginLeft: 15, marginTop: 15, zIndex: 0}}>
    //       <Mark something={something} />
    //     </div>
    //   </div>
    // </div>
  );
}

export default Documentation;
