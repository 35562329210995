import React from 'react';

export default function Navbar(props) {

  const logo = "https://res.cloudinary.com/dajxe0hhu/image/upload/v1677403895/codesplain-website/codesplain-white_gjzcbp.png";
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{ backgroundColor: '#171d37', zIndex: 999}}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/documentation">
          <img
            src={logo}
            alt="Logo"
            width="100%"
            height="100%"
            className="d-inline-block align-top"
            onClick={props.handleReload}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact
                </a>
              </li> */}
              <div className='d-flex align-items-center'>
                {!props.checked ? (
              <span className='text-white' style={{marginRight:10, }}>Code</span>

                ): (
              <span className='text-white' style={{marginRight:10 }}>Codesplain</span>

                )}

            <div className="form-check form-switch d-flex align-items-center" style={{marginRight: 12}}>
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={props.onToggle} checked={props.checked}/>
              {/* <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
            </div>
            </div>
            <li className="nav-item">
              <a className="btn btn-danger" href="/">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}