import React, {useState, useEffect} from "react";
import Utils from "../utils";
import Markdown from "markdown-to-jsx";
import Code from "./code";

const Mark = ({ something, checked }) => {
  // console.log(something);
  const file_name = Utils[something]?.codefragment;
  const file_name__2 = Utils[something]?.codefragment;

  const [post, setPost] = useState("");
  const [mdContent, setMdContent] = useState('');

  useEffect(() => {
    import(`../md-explain/${file_name}`)
      .then((res) => {
        console.log("res ===>", JSON.stringify(res))
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res));
      })
      .catch((err) => console.log(err));
  });

  useEffect(() => {
    // fetch the md file content and store it in the state variable
    import(`../md-code/${file_name__2}`)
    .then((res) => {
      console.log("res ===>", JSON.stringify(res))
      fetch(res.default)
        .then((res) => res.text())
        .then((res) => setMdContent(res));
    })
    .catch((err) => console.log(err));
  });


  return (
    <div>
      {!checked ? (
           <Markdown options={{ overrides: { Code: { component: Code } } }}>
        {mdContent}
         </Markdown>
      ):(
        <Markdown options={{ overrides: { Code: { component: Code } } }}>
           {post} 

      </Markdown>
      )}
     
    </div>
  );
};

export default Mark;